<template>
  <main>
    <top-view cn="加入乐享" en="Join Lohas Plus" :img="require('../../assets/img/bg_11@2x.jpg')"/>
    <div class="under-line-title">
      <h2>欢迎加入乐享无限大家庭</h2>
      <i></i>
    </div>
    <div class="text">
      <p>“乐享无限”是一个综合性自我增值平台，致力于打造中国最具价值的互动学习系统，提供一系列由国际专业财富管理协会认证的增值计划，</p>
      <p>透过线上专家团队精彩的内容分享，线下私属空间大咖丰富的课程讲授，引领大众走向财务自由，让每个家庭能真正拥有乐活、分享、品味</p>
      <p>的新时代生活。</p>
    </div>
<!--    <div class="double-line-title">-->
<!--      <h2>我们的福利待遇</h2>-->
<!--      <i></i>-->
<!--    </div>-->
<!--    <ul class="text-list">-->
<!--      <li><span>工作时间</span>9:30-18:00周末双休</li>-->
<!--      <li><span>工资架构</span>基本资薪+绩效奖金+全勤奖金+年资奖金+年终奖金</li>-->
<!--      <li><span>五险一金</span>社保+住房公积金</li>-->
<!--      <li><span>专业培训</span>定期组织内部培训</li>-->
<!--      <li><span>其他福利</span>不定期下午茶聚餐福利，每年一次团建，节日礼品及过节费，办公氛围融洽，同事相处和睦</li>-->
<!--    </ul>-->

    <div class="banner">
      <img src="../../assets/img/logo_05@2x.png" alt="">
      <p>一切精彩，尽在乐享</p>
      <div class="banner__text-line">
        <i></i>
        <i></i>
      </div>
      <p>期待你的加入，与我们同行</p>
      <a href="https://jobs.51job.com/all/co2611608.html" target="_blank" class="banner__btn">加入我们</a>
    </div>
  </main>
</template>

<script>
  import TopView from "../../components/common/TopView"
  export default {
    name: "Join",
    components: {
      TopView
    },
    metaInfo: {
      title: '乐享无限官网-加入乐享',
      meta: [
        {
          name: 'keyWords',
          content: '加入乐享无限,乐享无限招聘,智菁通',
        },
        {
          name: 'description',
          content: '乐享无限正在招聘,欢迎加入乐享无限集团和广州智菁通信息科技有限公司,一切精彩,尽在乐享,期待你的加入,与我们同行'
        }
      ]
    },
  }
</script>

<style lang="scss" scoped>
  main {
    text-align: center;
  }
.under-line-title {
  h2 {
    color: #333333;
    font-size: 36px;
    margin: 80px 0 30px;
  }
  i {
    display: inline-block;
    width: 90px;
    height: 8px;
    background: #f39800;
  }
}
.text {
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-top: 50px;
  margin-bottom: 80px;
}
.text-list {
  text-align: left;
  font-size: 16px;
  line-height: 30px;
  display: inline-block;
  margin-top: 50px;
  margin-bottom: 100px;
  li {
    span {
      font-size: 18px;
      margin-right: 20px;
    }
    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 10px;
      background: #f39800;
      border-radius: 10px;
    }
  }
}
.banner {
  width: 100%;
  height: 338px;
  background: url("../../assets/img/bg_12@2x.png") no-repeat center center;
  background-size: cover;
  img {
    margin: 25px 0;
  }
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
}
.banner__text-line {
  display: inline-block;
  @include clearfix;
  i {
    display: block;
    float: left;
    width: 150px;
    height: 3px;
    background: #ffffff;
    &:first-child {
      margin-right: 350px;
    }
  }
}
.banner__btn {
  display: inline-block;
  margin-top: 40px;
  width: 250px;
  height: 50px;
  line-height: 46px;
  background: none;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  outline: none;
  color: #ffffff;
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s;
  &:hover {
    color: #f39800;
    border-color: #f39800;
  }
}
</style>
